<template>
    <div v-if="loading == false">
        <UnapprovedClientsList
            :data_source="rows"
            :target_page="targetPage"
            :createAction="false"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :totalCount="totalCount"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
            @approveClients="approveClients"
        >
        </UnapprovedClientsList>
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import UnapprovedClientsList from '@/components/general/list/UnapprovedClientsList.vue';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { clients } from '@/util/apiRequests';

    export default {
        name: 'UnapprovedClients',
        components: { UnapprovedClientsList },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                targetPage: 'ClientOverview',
                loading: true,
                loadingTable: false,
                useOverride: false,
                showNewClientModal: false,
                totalCount: 0,
                storeKey: 'unapprovedClients',
                export_api: '/clients',
                columnMeta: [
                    { field: 'first_name', headerText: 'First Name' },
                    { field: 'last_name', headerText: 'Last Name' },
                    { field: 'guardian_name', headerText: 'Guardian Name' },
                    { field: 'status', headerText: 'Status' },
                    { field: 'dob', headerText: 'DOB' },
                    { field: 'email', headerText: 'Email' },
                    { field: 'phone', headerText: 'Phone number' },
                ],
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
        },
        methods: {
            async load_data() {
                try {
                    const body = {
                        criteria: {
                            ...this.criteria,
                            filter: {
                                'clients.status': this.criteria.filter.status ?? ['Unapproved'],
                            },
                        },
                        activate_status: 0,
                    };

                    const res = await this.$api.post(this.export_api, body);

                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch clients roster.');
                        return;
                    }

                    const data = res.data.rows
                        ?.slice()
                        .reverse()
                        .map((item) => {
                            const objData = { ...item };
                            if (objData.dob) {
                                const date = new Date(objData.dob);
                                objData.dob = `${String(date.getMonth() + 1).padStart(2, '0')}-${String(
                                    date.getDate()
                                ).padStart(2, '0')}-${date.getFullYear()}`;
                            }
                            delete objData.counselor;
                            delete objData.tags;
                            // delete objData.dob;
                            delete objData.activate_status;
                            delete objData.dashboardNames;
                            return objData;
                        });

                    this.rows = data;
                    this.totalCount = res.data.total_count;
                } catch (err) {
                    console.error(err);
                    this.$toasted.error('Failed to fetch clients roster.');
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                }
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                await this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
            async approveClients(data) {
                try {
                    this.loading = true;
                    let endpoint = clients.approveClients();
                    let res = await this.$api.post(endpoint, data);
                    if (res.data.success) {
                        await this.init();
                        await this.load_data();
                        this.$toasted.success(`Member ${data.activate_status ? 'approved' : 'Denied'} Successfully`);
                    }
                } catch (err) {
                    const errMsg = err ?? 'Failed to approve members';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
        },
        async created() {
            await this.init();
            await this.load_data();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                },
            },
        },
    };
</script>
