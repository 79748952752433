<template>
    <NoButtonModal :open="true">
        <button class="close-corner-x" type="button" @click.prevent="$emit('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <h1>Deny {{ selectedMembers.length > 1 ? 'Members' : 'Member' }}</h1>
        <p>Are you sure you want to deny selected {{ selectedMembers.length > 1 ? 'members' : 'member' }}?</p>
        <button @click="$emit('cancel')" class="button secondary">Cancel</button>
        <button @click="$emit('handleDeny', 'Denied')" class="button primary">Deny</button>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'DenyMemberModal',
        components: { NoButtonModal },
        props: {
            selectedMembers: {
                type: Array,
                default: () => [],
            },
        },
    };
</script>
