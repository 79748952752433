<template>
    <div v-if="ready == true">
        <div class="list-page-header do-not-print-me">
            <div class="list-header-left">
                <div class="breadcrumb">
                    <p>
                        <span class="page-title">Unapproved Members </span>
                    </p>
                </div>
                <div class="flex list-search">
                    <input
                        type="text"
                        v-model="searchTextRaw"
                        placeholder="Search"
                        ref="searchInput"
                        @blur="handleBlur"
                    />
                </div>
            </div>

            <div class="list-header-right">
                <button
                    v-if="showApproveBtn"
                    type="button"
                    class="primary"
                    @click="showApproveMemberModal = true"
                    :disabled="selectedRecords.length == 0 ? true : false"
                >
                    Approve
                </button>
                <button
                    v-if="showRejectBtn"
                    type="button"
                    class="primary"
                    @click="showDenyMemberModal = true"
                    :disabled="selectedRecords.length == 0 ? true : false"
                >
                    Deny
                </button>

                <div class="reset-all-criteria">
                    <button @click="clear_criteria" type="button" class="no-bg reset-all-criteria-button">
                        Reset View
                    </button>
                </div>

                <div class="filter-wrap">
                    <button @click="openFilter = !openFilter" type="button" class="no-bg filter-button">Filter</button>
                    <FilterList
                        :filters="JSON.parse(pageMeta.filters)"
                        @getFilters="modifyCriteria"
                        @closeFilter="(close) => (this.openFilter = close)"
                        v-if="openFilter"
                        :selectedFilters="criteria.filter"
                        class="filters-container"
                        id="filter-menu"
                    />
                </div>

                <div class="actions-wrap">
                    <button @click="show_hide_actions" class="actions-button no-bg">Actions</button>
                    <div class="actions-menu dropdown-menu" :class="actions_drop_down">
                        <div class="action-button">
                            <button class="no-bg" @click="export2exel">Export to Excel</button>
                        </div>
                        <div class="action-button">
                            <button class="no-bg" @click="export2csv">Export to CSV</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="unapproved-list custom-list-grid-standard">
            <ejs-grid
                ref="grid"
                :data-source="data_source"
                :allowFiltering="true"
                :allowSorting="true"
                :enableVirtualization="true"
                :rowSelected="onRowSelected"
                :rowDeselected="onRowDeselected"
                :sortSettings="sortOptions"
                :aria-rowcount="100"
                :allowPaging="false"
                :pageSettings="{ pageSize: 100 }"
            >
                <e-columns>
                    <e-column
                        v-for="column in formattedColumnMeta"
                        :key="column.field"
                        :field="column.field"
                        :headerText="column.headerText"
                        :allowSorting="column.allowSorting"
                        :editType="column.editType"
                        :type="column.type"
                        :displayAsCheckBox="column.displayAsCheckBox"
                        :textAlign="column.align"
                        :headerTextAlign="column.headerAlign"
                        :width="column.width"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <paginator
            :total_count="totalCount"
            :pageSize="criteria.page && criteria.page.num_per_page ? criteria.page.num_per_page : 10"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="$emit('cancelOverride')"
        />

        <ApproveMemberModal
            v-if="showApproveMemberModal"
            :selectedMembers="selectedRecords"
            @handleApprove="changeActivateStatus"
            @cancel="showApproveMemberModal = false"
        />

        <DenyMemberModal
            v-if="showDenyMemberModal"
            :selectedMembers="selectedRecords"
            @handleDeny="changeActivateStatus"
            @cancel="showDenyMemberModal = false"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { debounce } from 'lodash';
    import { GridPlugin, Sort, VirtualScroll, CommandColumn } from '@syncfusion/ej2-vue-grids';
    import ApproveMemberModal from './ApproveMemberModal.vue';
    import DenyMemberModal from './DenyMemberModal.vue';
    import Paginator from '@/components/general/paginator/paginator.vue';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import FilterList from '@/components/general/filter/filter.vue';
    import { Roles } from '@/util/globalConstants';
    import { ledger } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    Vue.use(GridPlugin);

    function makeAssociative(assocCol, data) {
        return data.reduce((out, cur) => {
            out[cur[assocCol]] = cur;
            return out;
        }, {});
    }

    export default {
        components: { Paginator, CommandColumn, FilterList, ApproveMemberModal, DenyMemberModal },
        props: {
            data_source: {
                type: Array,
                default: () => [],
            },
            totalCount: {
                type: Number,
                default: 0,
            },
            column_meta: {
                type: Array,
                required: false,
            },
            storeKey: {
                type: String,
                default: 'invalid_key',
            },
            useOverride: {
                type: Boolean,
                default: false,
            },
        },
        name: 'UnapprovedClientsList',
        data() {
            return {
                searchBar: null,
                openFilter: false,
                openColumn: false,
                showApproveMemberModal: false,
                showDenyMemberModal: false,
                currentPage: 1,
                sort: {},
                ready: false,
                searchTextRaw: '',
                actions_drop_down: '',
                selectedRecords: [],
                Roles,
            };
        },
        computed: {
            criteria() {
                let crits = this.useOverride ? this.overrideCriteria : this.savedCriteria;
                if (!crits.page) {
                    crits.page = {
                        current_page: 1,
                        num_per_page: 10,
                    };
                }
                if (!crits.page.current_page) {
                    crits.page.current_page = 1;
                }
                return crits;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            sortOptions: {
                get() {
                    const sortKeys = Object.keys(this.criteria?.sort || {});
                    return {
                        columns: sortKeys.map((key) => ({
                            field: key,
                            direction: this.criteria?.sort[key] || 'Ascending',
                        })),
                    };
                },
                set(obj) {
                    return obj;
                },
            },
            searchText() {
                return this.criteria?.search?.like_all || '';
            },
            formattedColumnMeta() {
                return this.column_maker();
            },
            showApproveBtn() {
                return this.criteria?.filter?.status?.length !== 2;
            },
            showRejectBtn() {
                if (this.criteria?.filter?.status?.length === 1) {
                    return !this.criteria?.filter?.status?.includes('Denied');
                }
                return this.criteria?.filter?.status?.length !== 2;
            },
        },
        methods: {
            commandClick: function (args) {
                this.$emit('buttonInCellClicked', args);
            },
            column_maker() {
                const columnMeta = [];
                let rec2push, meta_man, ok2push;

                if (this.column_meta) {
                    meta_man = makeAssociative('field', this.column_meta);
                }

                if (this.data_source.length) {
                    columnMeta.push({
                        type: 'checkbox',
                        field: 'selected',
                        width: '80px',
                    });
                }

                if (this.data_source && this.data_source.length) {
                    const dataSourceModel = this.data_source[0];

                    for (const key in dataSourceModel) {
                        if (key !== 'id' && !this.criteria?.column?.includes(key)) {
                            ok2push = 1;
                            rec2push = {
                                field: key,
                                headerText: this.convertStringToTitleCase(key),
                                allowFiltering: true,
                                allowSorting: true,
                            };

                            const columnWidths = {
                                first_name: '120px',
                                last_name: '120px',
                                guardian_name: '150px',
                                status: '100px',
                                dob: '100px',
                                phone_number: '120px',
                                email: '200px',
                            };

                            rec2push.width = columnWidths[key] || '120px';

                            if (meta_man && meta_man[key]) {
                                if (meta_man[key].hide || meta_man[key].hidden) {
                                    ok2push = 0;
                                } else {
                                    for (const [k, v] of Object.entries(meta_man[key])) {
                                        rec2push[k] = v;
                                    }
                                }
                            }
                            if (ok2push) {
                                columnMeta.push(rec2push);
                            }
                        }
                    }
                } else {
                    if (this.column_meta) {
                        for (const metaObj of this.column_meta) {
                            if (metaObj.field !== 'id' && !this.criteria?.column?.includes(metaObj.field)) {
                                columnMeta.push(metaObj);
                            }
                        }
                    }
                }

                return columnMeta;
            },
            convertStringToTitleCase(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                    return $1.toUpperCase();
                });
            },
            onRowSelected(args) {
                this.pushToSelectedRecords(args.data);
            },
            pushToSelectedRecords(data) {
                if (data.length) {
                    // Sometimes this can be an array, like when the select all checkbox is selected
                    data.forEach((d) => this.pushToSelectedRecords(d));
                }
                // Check that the data is not already in the selectedRecords
                else if (this.findIndexOfRecordInSelectedRecords(data) === -1) {
                    this.selectedRecords.push(data);
                }
            },
            findIndexOfRecordInSelectedRecords(data) {
                for (let selectedRecordI in this.selectedRecords) {
                    if (this.objectsMatch(this.selectedRecords[selectedRecordI], data)) {
                        return Number(selectedRecordI);
                    }
                }
                return -1;
            },
            objectsMatch(obj1, obj2) {
                let obj1Keys = Object.keys(obj1);
                let matchingKeyCount = 0;

                for (let obj1Key of obj1Keys) {
                    if (obj2[obj1Key] !== undefined && obj2[obj1Key] === obj1[obj1Key]) {
                        matchingKeyCount++;
                    }
                }

                return matchingKeyCount === obj1Keys.length && obj1Keys.length === Object.keys(obj2).length;
            },
            onRowDeselected(args) {
                if (args.data.length && args.isHeaderCheckboxClicked) {
                    /*
                        args.isHeaderCheckboxClicked == True when the deselection occurs due to the header checkbox being toggled
                        args.isHeaderCheckboxClicked == False when the deselection occurs due to the datasource changing
                    */

                    // this.selectedRecords = this.selectedRecords.filter((record) => {
                    //     for (let d of args.data) {
                    //         if (this.objectsMatch(d, record)) {
                    //             return false;
                    //         }
                    //     }
                    //     return true;
                    // });

                    this.selectedRecords = [];
                } else if (args.isInteracted) {
                    this.selectedRecords = this.selectedRecords.filter(
                        (record) => !this.objectsMatch(args.data, record)
                    );
                }
            },
            debounceSearch: debounce(function () {
                const newCriteria = { ...this.criteria };
                this.$emit('cancelOverride');

                if (this.searchTextRaw) {
                    newCriteria.search = { like_all: this.searchTextRaw };
                } else {
                    newCriteria.search = {};
                }

                newCriteria.page = {
                    ...newCriteria.page,
                    page_num: 1,
                };

                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: newCriteria,
                });
                this.$refs.searchInput?.focus();
            }, 500),
            getStoreData() {
                this.searchTextRaw = this.searchText;
                this.ready = true;
            },
            handleBlur(e) {
                if (e.relatedTarget?.classList?.contains('e-focus')) {
                    this.$refs.searchInput?.focus();
                }
            },
            clear_criteria() {
                this.searchTextRaw = '';
                this.selectedRecords = [];
                this.$emit('cancelOverride');
                this.$store.commit('filters/applyDefaultFilter', this.storeKey);
            },
            show_hide_actions() {
                if (this.actions_drop_down == '') {
                    this.actions_drop_down = 'show_actions_drop_down';
                } else {
                    this.actions_drop_down = '';
                }
            },
            async export2exel() {
                await this.exportApi('xls');
            },
            async export2csv() {
                await this.exportApi('csv');
            },
            async exportApi(type) {
                if (this.export_api && this.export_api != '/invalid_api') {
                    const exp_source = await this.export_body();

                    if (exp_source) {
                        if (exp_source[0]) {
                            this.exportSource(exp_source, type);
                        }
                    }
                } else {
                    //Default source if no export prop is found
                    if (this.data_source[0]) {
                        this.exportSource(this.data_source, type);
                    }
                }
            },
            async export_body() {
                const body = {
                    criteria: this.criteria,
                };
                const body2 = this.$clone_obj(body);
                body2.criteria.page.num_per_page = 10000;
                body2.criteria.page.page_num = 1;

                try {
                    if (this.export_api == '/ledger/transactions') {
                        const res3 = await this.$api.post(ledger.getTransactionList(), {
                            criteria: {
                                ...body2.criteria,
                                filter: {
                                    ...(this.criteria.filter || {}),
                                    'ledger.client_id': [this.client_id.toString()],
                                },
                            },
                            clientId: this.client_id,
                        });
                        const rows3 = res3.data.rows.map((row) => {
                            return {
                                id: row.id,
                                dayt_create: dayjs(row.date).format('MM/DD/YYYY'),
                                description: row.description,
                                charge_total: row.charge_total,
                                payment_total: row.payment_total,
                                type: row.type,
                                rawPayment: row.payment,
                                paymentType: row.paymentType,
                                paymentIntentId: row.stripePaymentIntentId,
                                availableForRefund: row.availableForRefund,
                            };
                        });
                        return rows3;
                    } else {
                        const res2 = await this.$api.post(this.export_api, body2);
                        return res2.data.rows;
                    }
                } catch (e) {
                    //Error: Invalid api root
                }
                return null;
            },
            exportSource(source, type) {
                let sresult = this.autoFormatExport(source);
                let headers = Object.entries(sresult[0]);
                //Get column names of the initial criteria, excluding the id
                let c_meta = this.column_meta
                    .filter((v) => !(v['field'] === 'id' || ('hide' in v && v.hide)))
                    .map((v) => v['field']);
                //Get and filter column headers based on current criteria
                let csv =
                    headers
                        .filter((v) => c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0]))
                        .map((v) => v[0])
                        .join(type === 'xls' ? '\t' : ',') + '\n';
                //List rows of results based on current and filtered criteria
                sresult.forEach((row) => {
                    csv +=
                        Object.entries(row)
                            .filter((v) => c_meta.includes(v[0]) && !this.criteria?.column?.includes(v[0]))
                            .map((v) => v[1])
                            .join(type === 'xls' ? '\t' : ',') + '\n';
                });
                const format_type =
                    type === 'xls' ? 'application/vnd.ms-excel;charset=utf-8' : 'text/csv;charset=utf-8';
                this.export2file(csv, format_type, type);
            },
            export2file(content, data_type, ext) {
                const anchor = document.createElement('a');
                anchor.href = 'data:' + data_type + ',' + encodeURIComponent(content);
                anchor.target = '_blank';
                anchor.download = this.storeKey + '.' + ext;
                anchor.click();
            },
            autoFormatExport(source) {
                let result = source;
                if (result.length) {
                    if (this.criteria.column && this.criteria.column.length > 0) {
                        let columnsToHide = this.criteria.column;
                        result = source.map((element) => {
                            let elementTemp = {};
                            for (let key in element) {
                                if (columnsToHide.indexOf(key) == -1) {
                                    elementTemp[key] = element[key];
                                }
                            }
                            return elementTemp;
                        });
                    }

                    for (const [k, v] of Object.entries(result)) {
                        for (const [k2, v2] of Object.entries(v)) {
                            let field = k2.toLowerCase();
                            if (
                                field.includes('total') ||
                                field.includes('amount') ||
                                field.includes('cost') ||
                                field.includes('price') ||
                                field.includes('dollars') ||
                                field.includes('balance')
                            ) {
                                result[k][k2] = this.$getCurrency(v2);
                            }
                            if ((field.includes('date') || field.includes('dayt')) && !this.ignoreDateConversion) {
                                result[k][k2] = this.getShortDate(v2);
                            }
                        }
                    }
                }
                return result;
            },
            async modifyCriteria(type, data) {
                const newCriteria = { ...this.criteria };
                switch (type) {
                    case 'column':
                        newCriteria.column = data;
                        this.doSort();
                        this.openColumn = false;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'sort':
                        const { header, direction } = data;
                        const [sortKey] = Object.keys(newCriteria.sort);

                        // Do nothing if no sort currently applied and the sort command is empty
                        if (header === undefined && sortKey === undefined) {
                            break;
                        }

                        // Do nothing if list is already sorted in the way described by the sort command
                        if (header === sortKey && newCriteria.sort[sortKey] === direction) {
                            break;
                        }

                        if (data.header && data.direction) {
                            newCriteria.sort = { [data.header]: data.direction };
                        } else {
                            newCriteria.sort = {};
                        }

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'pagination':
                        const { num_per_page, page_num } = data;
                        newCriteria.page = { num_per_page, page_num };

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'filter':
                        this.openFilter = false;
                        newCriteria.filter = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'search':
                        if (data.term) {
                            newCriteria.search = { like_all: data.term };
                        } else {
                            newCriteria.search = {};
                        }

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    case 'date_range':
                        newCriteria.date_range = data;

                        this.$emit('cancelOverride');

                        this.$store.commit('filters/updateFilter', {
                            stateKey: this.storeKey,
                            criteria: newCriteria,
                        });

                        break;
                    default:
                        break;
                }
            },
            async changeActivateStatus(type, val = {}) {
                this.showApproveMemberModal = false;
                this.showDenyMemberModal = false;

                let data = {
                    ...val,
                    emails: [],
                    phoneNumbers: [],
                    status: type,
                    activate_status: type === 'Active' ? 1 : 0,
                };
                this.selectedRecords.map((item) => {
                    if (item.email) {
                        data.emails.push(item.email);
                    }
                    if (item.phone) {
                        data.phoneNumbers.push(item.phone);
                    }
                });
                this.$emit('approveClients', data);
            },
        },
        created() {
            this.getStoreData();
        },
        provide: {
            grid: [Sort, VirtualScroll, CommandColumn],
        },
        watch: {
            searchTextRaw() {
                this.debounceSearch();
            },
            data_source: function (newDataSource) {
                let indexesThatShouldBeSelected = [];

                // Compare selected records to the new data source and find all matches.
                for (let i in newDataSource) {
                    if (this.findIndexOfRecordInSelectedRecords(newDataSource[i]) !== -1) {
                        indexesThatShouldBeSelected.push(i);
                    }
                }
                /*
                    This timeout is used to wait for the grid to finish updating with the new datasource before selecting the rows.
                    Getting the element by class name and clicking it is hacky, but syncfusion sucks and this was the only
                    way I could find of selecting the row without having the user click twice to unselect it again.
                */
                setTimeout(
                    () =>
                        indexesThatShouldBeSelected.forEach((i) =>
                            document.getElementsByClassName('e-gridchkbox')[i].click()
                        ),
                    100
                );
            },
        },
    };
</script>

<style scoped>
    button:disabled {
        background: gray !important;
        cursor: no-drop;
    }
    div.list-header-right div.actions-wrap div.show_actions_drop_down {
        visibility: visible;
    }
</style>

<style>
    .unapproved-list .e-uncheck,
    .unapproved-list .e-checkbox-wrapper .e-icons {
        border: 2px solid var(--button-color) !important;
    }
    .unapproved-list .e-headercell .e-uncheck,
    .unapproved-list .e-headercell .e-stop {
        background-color: white !important;
    }
</style>
